@use '@shared/util-styling/mixins';

.results-wrapper {
  margin-top: var(--spacing-16);
}

.total-results {
  color: var(--mdrt-color-text-on-light-tertiary);
  font: var(--mdrt-title-3);
}

.results {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-top: var(--spacing-12);
  padding-bottom: var(--spacing-40);

  @include mixins.breakpoint('small') {
    gap: var(--spacing-32);
  }
}

.content-wrapper {
  width: 100%;
}
