@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

$_separator-width: 4px;
$_separator-spacing: var(--spacing-8);

@mixin separator($beforeOrAfter: 'before') {
  @if $beforeOrAfter == 'before' {
    align-items: center;
    display: inline-flex;
  }

  &::#{$beforeOrAfter} {
    background-color: var(--mdrt-color-text-on-light-disabled);
    border-radius: 50%;
    content: '';
    display: inline-block;
    flex-shrink: 0;
    height: $_separator-width;
    margin-inline: $_separator-spacing;
    width: $_separator-width;
  }
}

.result {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  height: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration-color: transparent;
  }

  @include mixins.breakpoint('small') {
    flex-direction: row;
  }

  &__image {
    border-radius: var(--mdrt-corner-radius-m);
    display: none;
    flex-shrink: 0;
    height: 140px;
    object-fit: cover;
    width: 140px;

    @include mixins.breakpoint('small') {
      display: block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    font-weight: var(--font-weight-regular);
    height: 100%;
  }

  &__header {
    align-items: center;
    display: flex;
    font: var(--mdrt-body-4);
    justify-content: space-between;
    margin-bottom: var(--spacing-4);
  }

  &__details {
    align-items: center;
    display: flex;
  }

  &__type {
    margin-right: var(--spacing-8);
  }

  &__type-icon-container {
    display: inline-block;
    height: var(--mdrt-size-s);
    width: var(--mdrt-size-s);
  }

  &__authors {
    @include mixins.line-clamp;

    color: var(--mdrt-color-text-on-light-tertiary);
    min-width: 50px;
    word-break: break-all;

    @include mixins.breakpoint('small', max) {
      &--with-separator {
        @include separator('after');

        padding-right: calc($_separator-width + $_separator-spacing * 2);
        position: relative;

        &::after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__date {
    color: var(--mdrt-color-text-on-light-tertiary);
    display: none;
    flex-shrink: 0;

    @include mixins.breakpoint('small') {
      display: inline-flex;

      &--with-separator {
        @include separator;
      }
    }
  }

  &__read-time {
    color: var(--mdrt-color-text-on-light-tertiary);
    flex-shrink: 0;

    @include mixins.breakpoint('small') {
      &--with-separator {
        @include separator;
      }
    }
  }

  &__bookmark-icon {
    color: tokens.$mdrt-icon-button-icon-fill;
    height: tokens.$mdrt-icon-button-icon-size;
    width: tokens.$mdrt-icon-button-icon-size;
  }

  &__title {
    @include mixins.line-clamp(2);

    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-headline-4);
    margin-bottom: var(--spacing-6);
    padding-bottom: var(
      --spacing-2
    ); // this ensures that letters that go below the baseline (g, j, q, y and p) are not cut-off by the overflow:hidden
  }

  &__description {
    @include mixins.line-clamp(2);

    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-body-3-long);
  }

  &__footer {
    display: flex;
    margin-top: auto;
  }
}
